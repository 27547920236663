<template>
  <div class="mx-2 my-2">
    <v-row>
      <v-col cols="12" lg="2" xl="2">
        <v-row>
          <v-col cols="12">
            <v-select
              :items="mainPageReport.months"
              v-model="selectedMonthId"
              item-text="nameRu"
              item-value="id"
              color="teal"
              key="id"
              dense
              outlined
              hide-details
              class="rounded-0"
              v-if="mainPageReport != null"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="1" tile>
              <v-card-title class="viortis-color white--text py-3">
                <span class="text-subtitle-2">Категории</span>
              </v-card-title>
              <v-card-text class="px-0 py-0">
                <v-list
                  dense
                  class="overflow-y-auto"
                  v-if="mainPageReport != null"
                >
                  <v-list-item-group v-model="selectedCategoryItem" color="red">
                    <template v-for="(categoryItem, index) in categoryItems">
                      <v-list-item
                        :key="'categoryItem' + categoryItem.id"
                        :value="categoryItem.id"
                      >
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="categoryItem.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <v-divider
                        v-if="index < categoryItems.length - 1"
                        :key="'cat_dvd_' + categoryItem.id"
                      />
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="7" xl="7">
        <v-card elevation="0" tile>
          <v-card-text>
            <Map :selectedCategoryItem="selectedCategoryItem" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" xl="3">
        <v-row>
          <v-col cols="12">
            <v-card elevation="1" tile>
              <v-card-title class="viortis-color white--text py-3">
                <span class="text-subtitle-2">Выбор категории</span>
              </v-card-title>
              <v-card-text class="px-0 py-0">
                <v-list
                  dense
                  class="overflow-y-auto"
                  v-if="mainPageReport != null"
                >
                  <v-list-item-group
                    v-model="selectedRegionCategoryItem"
                    color="red"
                  >
                    <template
                      v-for="(
                        regionCategoryItem, index
                      ) in mainPageReport.regionCategories"
                    >
                      <v-list-item
                        :key="'regionCategoryItem' + regionCategoryItem.id"
                        :value="regionCategoryItem.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="regionCategoryItem.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="teal"
                                small
                                v-bind="attrs"
                                v-on="on"
                                >mdi-information-outline</v-icon
                              >
                            </template>
                            <div>
                              <template
                                v-for="categoryRegionItem in mainPageReport.regions.filter(
                                  (x) =>
                                    x.regionCategoryId == regionCategoryItem.id
                                )"
                              >
                                <span :key="'cri_' + categoryRegionItem.id">{{
                                  categoryRegionItem.nameRu
                                }}</span>
                                <v-divider
                                  :key="'cridiv_' + categoryRegionItem.id"
                                />
                              </template>
                            </div>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider
                        v-if="
                          index < mainPageReport.regionCategories.length - 1
                        "
                        :key="'regionCat_dvd_' + regionCategoryItem.id"
                      />
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="1" tile>
              <v-card-text class="mt-3" v-if="this.selectedItem != null">
                <v-row>
                  <v-col
                    cols="2"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: mixed;
                      border-right: 1px solid white;
                    "
                    class="d-flex justify-center align-center viortis-color white--text"
                  >
                    Ежеквартальный оборот
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <v-row>
                      <v-col
                        cols="2"
                        class="d-flex justify-center align-center viortis-color white--text"
                        style="
                          border-right: 1px solid white;
                          border-bottom: 1px solid white;
                        "
                      >
                        <span>A1</span>
                      </v-col>
                      <v-col
                        cols="4"
                        class="viortis-color white--text"
                        style="
                          border-right: 1px solid white;
                          border-bottom: 1px solid white;
                        "
                      >
                        <span>Более</span>
                      </v-col>
                      <v-col cols="6" style="border-bottom: 1px solid #3fa0a5">
                        <span>{{
                          selectedItem
                            .find((x) => x.categoryId == 1)
                            .minSum.toLocaleString()
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="2"
                        class="d-flex justify-center align-center viortis-color white--text"
                        style="
                          border-right: 1px solid white;
                          border-bottom: 1px solid white;
                        "
                      >
                        <span>A2</span>
                      </v-col>
                      <v-col cols="4">
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            От
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            До
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 2)
                                .minSum.toLocaleString()
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 2)
                                .maxSum.toLocaleString()
                            }}</span></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="2"
                        class="d-flex justify-center align-center viortis-color white--text"
                        style="
                          border-right: 1px solid white;
                          border-bottom: 1px solid white;
                        "
                      >
                        <span>B</span>
                      </v-col>
                      <v-col cols="4">
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            От
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            До
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 3)
                                .minSum.toLocaleString()
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 3)
                                .maxSum.toLocaleString()
                            }}</span></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="2"
                        class="d-flex justify-center align-center viortis-color white--text"
                        style="
                          border-right: 1px solid white;
                          border-bottom: 1px solid white;
                        "
                      >
                        <span>C</span>
                      </v-col>
                      <v-col cols="4">
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            От
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            До
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 4)
                                .minSum.toLocaleString()
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 4)
                                .maxSum.toLocaleString()
                            }}</span></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="2"
                        class="d-flex justify-center align-center viortis-color white--text"
                        style="border-right: 1px solid white"
                      >
                        <span>D</span>
                      </v-col>
                      <v-col cols="4">
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="
                              border-right: 1px solid white;
                              border-bottom: 1px solid white;
                            "
                          >
                            От
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="viortis-color white--text"
                            style="border-right: 1px solid white"
                          >
                            До
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col
                            cols="12"
                            style="border-bottom: 1px solid #3fa0a5"
                          >
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 5)
                                .minSum.toLocaleString()
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <span>{{
                              selectedItem
                                .find((x) => x.categoryId == 5)
                                .maxSum.toLocaleString()
                            }}</span></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="10">
        <v-carousel hide-delimiters show-arrows-on-hover cycle>
          <v-carousel-item
            reverse-transition="fade-transition"
            transition="fade-transition"
            v-for="slide in slides"
            :key="slide.id"
            @click="openLink(slide.fileName)"
          >
            <v-img
              :src="currentLanguage == 'ru' ? slide.srcRu : slide.srcEn"
              contain
              height="100%"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="900">
      <v-card>
        <v-card-title class="viortis-color white--text">
          Новый продукт
          <v-spacer />
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2">
          <div v-if="videoDialogStatus">
            <video width="100%" autoplay muted controls>
              <source src="../../assets/video/numeric.mp4" type="video/mp4" />
              Ваш браузер не поддерживает воспроизведение видео.
            </video>
          </div>
          <div v-else>
            <table
              border="1"
              style="
                width: 50%;
                margin: 0 auto;
                text-align: center;
                border-collapse: collapse;
              "
            >
              <tr v-for="(manager, index) in managers" :key="index">
                <td>
                  <v-img
                    src="../../assets/wp.svg"
                    width="36"
                    height="36"
                    contain
                    class="mx-auto"
                  />
                </td>
                <td>
                  <span>{{ manager.name }}</span>
                </td>
                <td>
                  <v-btn x-small elevation="1" @click="openWpLink(manager.link)"
                    >Связаться</v-btn
                  >
                </td>
              </tr>
            </table>
          </div>
          <div class="text-center my-2">
            <v-btn
              class="text-capitalize white--text font-weight-light"
              style="background-color: #1d6d7d"
              small
              @click="videoDialogStatus = false"
              v-if="videoDialogStatus"
            >
              Связаться с менеджером
            </v-btn>
            <v-btn
              class="text-capitalize white--text font-weight-light"
              style="background-color: #1d6d7d"
              small
              @click="videoDialogStatus = true"
              v-else
            >
              Ознакомиться с продуктом
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Map from "./Map.vue";
export default {
  name: "Main",
  components: {
    Map,
  },
  computed: {
    ...mapGetters({
      getMainPageReport: "unload/GET_MAIN_PAGE_REPORT",
    }),
    mainPageReport() {
      return this.getMainPageReport;
    },
  },
  data: () => ({
    videoDialogStatus: true,
    dialog: true,
    managers: [
      {
        name: "Александр Дин",
        link: "https://api.whatsapp.com/send/?phone=77082180981&text=Добрый день, Александр. Меня интересует Ваш новый продукт Нумерическая / Взвешенная дистрибуция.&type=phone_number&app_absent=0",
      },
      {
        name: "Нариманов Рустем",
        link: "https://api.whatsapp.com/send/?phone=77084826803&text=Добрый день, Рустем. Меня интересует Ваш новый продукт Нумерическая / Взвешенная дистрибуция.&type=phone_number&app_absent=0",
      },
      {
        name: "Александр Чередниченко",
        link: "https://api.whatsapp.com/send/?phone=77073351949&text=Добрый день, Александр. Интересует Ваш новый продукт Нумерическая / Взвешенная дистрибуция.&type=phone_number&app_absent=0",
      },
    ],
    selectedCategoryItem: 1,
    selectedRegionCategoryItem: 1,
    selectedItem: null,
    selectedMonthId: null,
    initialFlag: true,
    categoryItems: [
      { id: 1, name: "A1" },
      { id: 2, name: "A2" },
      { id: 3, name: "B" },
      { id: 4, name: "C" },
      { id: 5, name: "D" },
    ],
    slides: [
      {
        id: 1,
        srcRu: require("../../assets/slides/slide1-ru.png"),
        srcEn: require("../../assets/slides/slide1-en.png"),
        fileName: "spa.pdf",
      },
      {
        id: 3,
        srcRu: require("../../assets/slides/slide3-ru.png"),
        srcEn: require("../../assets/slides/slide3-en.png"),
        fileName: "dolevoy-sety.pdf",
      },
      {
        id: 4,
        srcRu: require("../../assets/slides/slide4-ru.png"),
        srcEn: require("../../assets/slides/slide4-en.png"),
        fileName: "geo.pdf",
      },
      {
        id: 5,
        srcRu: require("../../assets/slides/slide5-ru.png"),
        srcEn: require("../../assets/slides/slide5-en.png"),
        fileName: "tender.pdf",
      },
      {
        id: 6,
        srcRu: require("../../assets/slides/slide6-ru.png"),
        srcEn: require("../../assets/slides/slide6-en.png"),
        fileName: "pdp.pdf",
      },
      {
        id: 7,
        srcRu: require("../../assets/slides/slide7-ru.png"),
        srcEn: require("../../assets/slides/slide7-en.png"),
        fileName: "vd.pdf",
      },
    ],
    currentLanguage: "ru",
  }),
  methods: {
    ...mapActions({
      getMainPageReportFromApi: "unload/GET_MAIN_PAGE_REPORT_FROM_API",
    }),
    ...mapMutations({
      setSelectedTabId: "tab/SET_SELECTED_TAB_ID",
    }),
    getRegionCategoryLimits() {
      if (this.selectedRegionCategoryItem != null) {
        let regionCategoryItem = this.mainPageReport.regions.find(
          (x) => x.regionCategoryId == this.selectedRegionCategoryItem
        );
        let selectedCategoryLimits = this.mainPageReport.categoryLimits.filter(
          (x) => x.regionId == regionCategoryItem.id
        );
        this.selectedItem = selectedCategoryLimits;
      }
    },
    async getMainPageReportFromApiMethod(monthId) {
      await this.getMainPageReportFromApi({ monthId: monthId });
      this.getRegionCategoryLimits();
    },
    openLink(filename) {
      window.open(
        window.location.protocol +
          "//" +
          window.location.host +
          "/static/" +
          filename,
        "_blank"
      );
    },
    openWpLink(link) {
      window.open(link, "_blank");
    },
  },
  watch: {
    selectedRegionCategoryItem: function () {
      this.getRegionCategoryLimits();
    },
    selectedMonthId: function () {
      if (!this.initialFlag) {
        this.getMainPageReportFromApiMethod(this.selectedMonthId);
      } else {
        this.initialFlag = false;
      }
    },
  },
  async created() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }

    this.setSelectedTabId(0);
    await this.getMainPageReportFromApiMethod(0);
    this.selectedMonthId = this.mainPageReport.months[0].id;
  },
};
</script>

<style scoped>
.viortis-background {
  background-color: #1d6d7d;
}
.viortis-color {
  background-color: #3fa0a5;
}
</style>
